<template>
  <div class="container">
    <!-- <el-tabs v-model="activeName" @tab-click="handleClick"> -->
    <el-tabs v-model="activeName">
      <el-tab-pane label="账号管理" name="first">
        <el-form :inline="true" :model="formInline" ref="formInline" label-position="right" class="demo-form-inline"
          label-width="90px">
          <el-form-item label="禁用状态：" prop="enabledStatus">
            <el-select v-model="formInline.enabledStatus" placeholder="请选择" clearable>
              <el-option v-for="(item, index) in enabledStatusList" :key="index" :label="item == 0 ? '禁用' : '启用'"
                :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号状态：" prop="accountStatus">
            <el-select v-model="formInline.accountStatus" placeholder="请选择" clearable>
              <el-option v-for="item in accountStatusList" :key="item" :label="item == 0 ? '申请删除' : '使用中'"
                :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="账号角色：" prop="roleId">
            <el-select v-model="formInline.roleId" placeholder="请选择" clearable>
              <el-option v-for="item in roleIdList" :key="item" :label="item == 3 ? '用户' : item == 2 ? '管理员' : '超级管理员'
                " :value="item"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item class="button_left">
            <el-button type="primary" @click="onSubmit(1)" icon="el-icon-search">查询</el-button>
            <el-button class="button_reset" @click="resetForm('formInline')" plain icon="el-icon-refresh">重置</el-button>
          </el-form-item>
          <el-form-item class="button_right">
            <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
              icon="el-icon-info" icon-color="red" title="确定删除吗?" @confirm="handleDeleteAll">
              <el-button slot="reference" type="danger" icon="el-icon-delete">
                删除
              </el-button>
            </el-popconfirm>
            <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
              icon="el-icon-info" icon-color="red" title="确定取消删除申请吗?" @confirm="handleRecoverAccountAll">
              <el-button slot="reference" icon="el-icon-refresh-left" plain>
                取消删除申请
              </el-button>
            </el-popconfirm>
            <el-button @click="drawer = true; drawerTitle = '添加账号';" type="primary" icon="el-icon-circle-plus-outline">
              添加账号
            </el-button>
          </el-form-item>
        </el-form>

        <el-table ref="multipleTable" :data=" formattedUserInfoList " tooltip-effect="dark" style="width: 100%"
          @selection-change=" handleSelectionChange " stripe :height=" height " size="mini">
          <el-table-column type="selection" width="55" fixed> </el-table-column>
          <el-table-column type="index" width="60" label="序号">
          </el-table-column>
          <el-table-column prop="user_name" label="用户名"> </el-table-column>
          <el-table-column prop="gender" label="性别">
            <template slot-scope="scope">
              <span v-if=" scope.row.gender == 0 ">女</span>
              <span v-else-if=" scope.row.gender == 1 ">男</span>
              <span v-else>保密</span>
            </template>
          </el-table-column>
          <el-table-column prop="role_name" label="角色"> </el-table-column>
          <el-table-column prop="email" label="邮箱" :width=" 220 ">
          </el-table-column>

          <el-table-column prop="enabled_status" label="禁用状态" :width=" 100 ">
            <template slot-scope="scope">
              <span v-if=" scope.row.enabled_status == 0 ">禁用</span>
              <span v-else>启用</span>
            </template>
          </el-table-column>
          <el-table-column prop="account_status" label="账号状态" :width=" 100 ">
            <template slot-scope="scope">
              <span v-if=" scope.row.account_status == 0 ">申请删除</span>
              <span v-else>使用中</span>
            </template>
          </el-table-column>

          <el-table-column prop="user_create_time" label="账号创建时间" :width=" tabelColWidth ">
          </el-table-column>
          <!-- <el-table-column
            prop="role_create_time"
            label="角色创建时间"
            :width="tabelColWidth"
          >
          </el-table-column> -->

          <el-table-column label="操作" fixed="right" width="400">
            <template slot-scope="scope">
              <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
                icon="el-icon-info" icon-color="red" title="确定设为管理员吗？"
                @confirm=" handleUpdateRole(scope.$index, scope.row) ">
                <el-button slot="reference" type="text" class="red" v-show="
                  scope.row.role_name == '用户' && myUserInfo.user_id == 1
                ">
                  设为管理员
                </el-button>
              </el-popconfirm>
              <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
                icon="el-icon-info" icon-color="red" title="确定设为用户吗？"
                @confirm=" handleUpdateRole(scope.$index, scope.row) ">
                <el-button slot="reference" type="text" v-show="
                  scope.row.role_name == '管理员' && myUserInfo.user_id == 1
                ">
                  设为用户
                </el-button>
              </el-popconfirm>
              <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
                icon="el-icon-info" icon-color="red" title="确定禁用吗？" @confirm=" handleEnable(scope.$index, scope.row) ">
                <el-button slot="reference" type="text" class="red" v-show="
                  scope.row.enabled_status == 1 &&
                    scope.row.role_name != '超级管理员'
                ">
                  禁用
                </el-button>
              </el-popconfirm>

              <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
                icon="el-icon-info" title="确定启用吗？" @confirm=" handleEnable(scope.$index, scope.row) ">
                <el-button slot="reference" class="button_public" type="text" v-show=" scope.row.enabled_status == 0 ">
                  启用
                </el-button>
              </el-popconfirm>

              <el-button slot="reference" class="button_blue" type="text" @click="
                handleChangeEmail(scope.$index, scope.row);
                dialogFormVisible = true;
              ">
                修改邮箱
              </el-button>

              <el-button slot="reference" class="button_blue" type="text" @click="
                handleChangePassword(scope.$index, scope.row);
                dialogFormVisible2 = true;
              ">
                修改密码
              </el-button>

              <el-button type="text" @click="
                handleEdit(scope.$index, scope.row);
                drawerTitle = '编辑';
              ">
                编辑
              </el-button>

              <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
                icon="el-icon-info" icon-color="red" title="确定删除吗？" v-show="
                  (myUserInfo.user_id == 1 &&
                    scope.row.role_name != '超级管理员' &&
                    scope.row.account_status == 1) ||
                    (scope.row.role_name != '超级管理员' &&
                      scope.row.role_name != '管理员' &&
                      myUserInfo.role_id != 1 &&
                      scope.row.account_status == 1)
                " @confirm=" handleDelete(scope.$index, scope.row) ">
                <el-button type="text" slot="reference" class="red">
                  删除
                </el-button>
              </el-popconfirm>
              <el-popconfirm confirm-button-text="确定" cancel-button-text="取消" cancel-button-type="Primary"
                icon="el-icon-info" icon-color="red" title="确定取消删除申请吗?" v-show="
                  scope.row.role_name != '超级管理员' &&
                    scope.row.account_status == 0
                " @confirm=" handleRecover(scope.$index, scope.row) ">
                <el-button type="text" slot="reference">
                  取消删除申请
                </el-button>
              </el-popconfirm>
            </template>
          </el-table-column>
        </el-table>

        <el-pagination background @size-change=" handleSizeChange " @current-change=" handleCurrentChange "
          :current-page.sync=" currentPage " :page-sizes=" [10, 25, 50, 100] " :page-size=" 10 "
          layout="total, sizes, prev, pager, next" :total=" userTotal ">
        </el-pagination>

        <el-drawer :title=" drawerTitle " :visible.sync=" drawer " :with-header=" true " :before-close=" handleClose "
          size="39%" :destroy-on-close=" true ">
          <el-form :model=" ruleForm " :rules=" rules " ref="ruleForm" label-width="120px"
            v-show=" drawerTitle == '添加账号' ">
            <el-form-item label="用户名" prop="username">
              <el-input v-model=" ruleForm.username " placeholder="请填写用户名"></el-input>
            </el-form-item>
            <el-form-item v-show=" !disabled " label="密码" prop="password">
              <el-input v-model=" ruleForm.password " placeholder="请填写密码" show-password :disabled=" disabled "></el-input>
            </el-form-item>
            <el-form-item v-show=" !disabled " label="确认密码" prop="checkPassword">
              <el-input v-model=" ruleForm.checkPassword " placeholder="请填写确认密码" show-password
                :disabled=" disabled "></el-input>
            </el-form-item>
            <el-form-item label="邮箱" prop="email">
              <el-input placeholder="请填写邮箱" v-model=" ruleForm.email " />
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model=" ruleForm.code " placeholder="请填写验证码">
                <el-button class="button_red" slot="append" @click=" getCode(ruleForm.email) ">获取验证码</el-button>
              </el-input>
            </el-form-item>
            <el-form-item class="right">
              <el-button @click=" cancel " plain>取消</el-button>
              <el-button type="primary" @click=" confirm('ruleForm') ">确认</el-button>
            </el-form-item>
          </el-form>
          <el-form :model=" ruleForm2 " :rules=" rules " ref="ruleForm2" label-width="120px"
            v-show=" drawerTitle == '编辑' ">
            <el-form-item label="用户名" prop="username">
              <el-input v-model=" ruleForm2.username " placeholder="请填写用户名"></el-input>
            </el-form-item>
            <el-form-item label="性别" prop="gender">
              <el-select v-model=" ruleForm2.gender " placeholder="请选择">
                <el-option v-for="     item      in      genderList     " :key=" item "
                  :label=" item == 1 ? '男' : item == 0 ? '女' : '保密' " :value=" item ">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="头像">
              <div class="avatar_box" style="text-align: center">
                <img width="120px" v-if=" avatarURL " :src=" avatarURL " />
                <el-button type="text" @click=" editAvatar ">修改头像</el-button>
              </div>
            </el-form-item>

            <el-form-item class="right">
              <el-button @click=" cancel " plain>取消</el-button>
              <el-button type="primary" @click=" confirm('ruleForm2') ">确认</el-button>
            </el-form-item>
          </el-form>
        </el-drawer>

        <el-dialog title="修改邮箱" :visible.sync=" dialogFormVisible " :before-close=" handleClose " width="750px">
          <el-form :model=" form " ref="form" status-icon :rules=" rules " label-width="120px">
            <el-form-item label="邮箱" prop="oldEmail">
              <el-input v-model=" form.oldEmail " placeholder="请填写邮箱" disabled></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model=" form.code " placeholder="请填写验证码" clearable>
                <el-button class="button_red" slot="append" @click=" getCode(form.oldEmail) ">获取验证码</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="新邮箱" prop="newEmail">
              <el-input v-model=" form.newEmail " placeholder="请填写新邮箱" clearable></el-input>
            </el-form-item>
            <el-form-item label="确认新邮箱" prop="checkEmail">
              <el-input v-model=" form.checkEmail " placeholder="确认新邮箱" clearable></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click=" cancel ">取 消</el-button>
            <el-button type="primary" @click=" changeEmail('form') ">确 定</el-button>
          </div>
        </el-dialog>
        <el-dialog title="修改密码" :visible.sync=" dialogFormVisible2 " :before-close=" handleClose " width="750px">
          <el-form :model=" form2 " ref="form2" status-icon :rules=" rules " label-width="120px">
            <el-form-item label="邮箱" prop="email">
              <el-input v-model=" form2.email " placeholder="请填写邮箱" disabled></el-input>
            </el-form-item>
            <el-form-item label="验证码" prop="code">
              <el-input v-model=" form2.code " placeholder="请填写验证码" clearable>
                <el-button class="button_red" slot="append" @click=" getCode(form2.email) ">获取验证码</el-button>
              </el-input>
            </el-form-item>
            <el-form-item label="新密码" prop="newPassword">
              <el-input v-model=" form2.newPassword " placeholder="请填写新密码" show-password clearable></el-input>
            </el-form-item>
            <el-form-item label="确认新密码" prop="checkNewPassword">
              <el-input v-model=" form2.checkNewPassword " placeholder="确认新密码" show-password clearable></el-input>
            </el-form-item>
          </el-form>
          <div slot="footer" class="dialog-footer">
            <el-button @click=" cancel ">取 消</el-button>
            <el-button type="primary" @click=" changePassword('form2') ">确 定</el-button>
          </div>
        </el-dialog>
        <!-- 修改头像 -->
        <el-dialog title="修改头像" :visible.sync=" editAvatarDialog ">
          <el-row type="flex" justify="center">
            <div class="cropper">
              <vueCropper ref="cropper" :img=" option.img " :outputSize=" option.size " :outputType=" option.outputType "
                :info=" option.info " :full=" option.full " :canMove=" option.canMove " :canMoveBox=" option.canMoveBox "
                :original=" option.original " :autoCrop=" option.autoCrop " :autoCropWidth=" option.autoCropWidth "
                :autoCropHeight=" option.autoCropHeight " :fixedBox=" option.fixedBox " @realTime=" realTime ">
              </vueCropper>
            </div>
            <div class="previewBox">
              <div :style=" previews.div " class="preview">
                <img :src=" previews.url " :style=" previews.img " />
              </div>
              <el-row type="flex" justify="center">
                <el-upload action="" :show-file-list=" false " :auto-upload=" false " :on-change=" uploadImg ">
                  <el-button size="mini" type="primary"> 选择头像 </el-button>
                </el-upload>
              </el-row>
              <br />
              <el-row>
                <el-button icon="el-icon-plus" circle size="mini" @click=" changeScale(1) "></el-button>
                <el-button icon="el-icon-minus" circle size="mini" @click=" changeScale(-1) "></el-button>
                <el-button icon="el-icon-download" circle size="mini" @click=" down('blob') "></el-button>
                <el-button icon="el-icon-refresh-left" circle size="mini" @click=" rotateLeft "></el-button>
                <el-button icon="el-icon-refresh-right" circle size="mini" @click=" rotateRight "></el-button>
              </el-row>
            </div>
          </el-row>
          <span slot="footer" class="dialog-footer">
            <el-button @click=" editAvatarDialog = false ">取 消</el-button>
            <el-button type="primary" @click=" saveEditAvatar ">确 定</el-button>
          </span>
        </el-dialog>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import { VueCropper } from "vue-cropper";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
  name: "AccountManage",
  components: { VueCropper },
  data() {
    var validateEmail = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新邮箱"));
      } else {
        if (this.form.checkEmail !== "") {
          this.$refs.form.validateField("checkEmail");
        }
        callback();
      }
    };
    var validateEmail2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入新邮箱"));
      } else if (value !== this.form.newEmail) {
        callback(new Error("两次输入新邮箱不一致!"));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.ruleForm.checkPassword !== "") {
          this.$refs.ruleForm.validateField("checkPassword");
        }
        callback();
      }
    };
    var validateNewPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入新密码"));
      } else {
        if (this.form2.checkPassword !== "") {
          this.$refs.form2.validateField("checkPassword");
        }
        callback();
      }
    };
    var validateNewPass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form2.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.password) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      disabled: false,
      dialogFormVisible: false,
      dialogFormVisible2: false,
      editAvatarDialog: false,
      height: 740,
      tabelColWidth: 230,
      currentPage: 1,
      drawerTitle: "",
      drawer: false,
      activeName: "first",
      userIds: [],
      enabledStatusList: [0, 1],
      accountStatusList: [0, 1],
      roleIdList: [1, 2, 3],
      genderList: [2, 1, 0],
      formData: new FormData(),
      multipleSelection: [],
      previews: {},
      avatarURL: null,
      option: {
        img: "", // 裁剪图片的地址
        info: true, // 裁剪框的大小信息
        outputSize: 1, // 剪切后的图片质量（0.1-1）
        full: true, // 输出原图比例截图 props名full
        outputType: "png", // 裁剪生成额图片的格式
        canMove: true, // 能否拖动图片
        original: false, // 上传图片是否显示原始宽高
        canMoveBox: false, // 能否拖动截图框
        autoCrop: true, // 是否默认生成截图框
        autoCropWidth: 300, // 默认生成截图框宽度
        autoCropHeight: 300, // 默认生成截图框高度
        fixedBox: false, // 截图框固定大小
        centerBox: false, // 截图框是否被限制在图片里面
        infoTrue: true, // true 为展示真实输出图片宽高 false 展示看到的截图框宽高
      },
      formInline: {
        currentPage: 1,
        pageSize: 10,
        enabledStatus: null,
        accountStatus: null,
        roleId: null,
      },
      ruleForm: {
        username: null,
        password: null,
        checkPassword: null,
        email: null,
        code: null,
      },
      ruleForm2: {
        userId: null,
        username: null,
        gender: 1,
        avatar: null,
      },
      form: {
        userId: null,
        oldEmail: null,
        newEmail: null,
        code: null,
        checkEmail: null,
      },
      form2: {
        userId: null,
        newPassword: null,
        checkNewPassword: null,
        codeId: null,
        code: null,
        email: null,
      },
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "blur" },
          {
            min: 3,
            max: 10,
            message: "长度在 3 到 10 个字符",
            trigger: ["blur", "change"],
          },
        ],
        password: [
          { validator: validatePass, trigger: "blur" },
          { required: true, message: "请输入密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        newPassword: [
          { validator: validateNewPass, trigger: "blur" },
          { required: true, message: "请输入新密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        checkPassword: [
          { validator: validatePass2, trigger: "blur" },
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        checkNewPassword: [
          { validator: validateNewPass2, trigger: "blur" },
          { required: true, message: "请确认密码", trigger: "blur" },
          {
            min: 6,
            max: 16,
            message: "长度在 6 到 16 个字符",
            trigger: ["blur", "change"],
          },
        ],
        code: [
          { required: true, message: "请输入验证码", trigger: "blur" },
          {
            min: 1,
            max: 8,
            message: "长度在 1 到 8 个字符",
            trigger: ["blur", "change"],
          },
        ],
        email: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        newEmail: [
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        oldEmail: [
          { validator: validateEmail, trigger: "blur" },
          { required: true, message: "请输入邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        checkEmail: [
          { validator: validateEmail2, trigger: "blur" },
          { required: true, message: "请确认邮箱地址", trigger: "blur" },
          {
            type: "email",
            message: "请输入正确的邮箱地址",
            trigger: ["blur", "change"],
          },
        ],
        gender: [{ required: true, message: "请选择性别", trigger: "blur" }],
      },
    };
  },
  computed: {
    ...mapState("user", ["myUserInfo"]),
    ...mapState("account", ["userInfoList", "status", "userTotal", "message"]),
    ...mapState("login", {
      codeStatus: "status",
      codeMessage: "message",
      codeId: "codeId",
    }),
    ...mapGetters("account", ["formattedUserInfoList"])
  },
  methods: {
    ...mapActions("login", { getCodeId: "getCodeId" }),
    ...mapActions("account", {
      getAllUserinfoList: "getAllUserinfoList",
      getUserinfoListByAccountStatus: "getUserinfoListByAccountStatus",
      getUserinfoListByEnabledStatus: "getUserinfoListByEnabledStatus",
      getUserinfoListByAll: "getUserinfoListByAll",
      getUserinfoListByRoleId: "getUserinfoListByRoleId",
      getUserinfoListByAccountStatusAndRoleId:
        "getUserinfoListByAccountStatusAndRoleId",
      getUserinfoListByEnabledStatusAndRoleId:
        "getUserinfoListByEnabledStatusAndRoleId",
      getUserinfoListByEnabledStatusAndAccountStatus:
        "getUserinfoListByEnabledStatusAndAccountStatus",
      addAccount: "addAccount",
      updateUserinfo: "updateUserinfo",
      deleteAccount: "deleteAccount",
      recoverAccount: "recoverAccount",
      enableAccount: "enableAccount",
      updateEmail: "updateEmail",
      updatePassword: "updatePassword",
      updateRole: "updateRole",
    }),
    // 更改角色
    handleUpdateRole(index, row) {
      let roleName = row.role_name == "管理员" ? "用户" : "管理员";
      let userId = row.user_id;
      this.updateRole({ roleName, userId }).then(() => {
        if (this.status == 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.message,
          });
          this.onSubmit();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.message,
          });
        }
      });
    },
    // 修改 邮箱 密码
    handleChangeEmail(index, row) {
      this.form.userId = row.user_id;
      this.form.oldEmail = row.email;
      // console.log(index, row);
    },
    changeEmail(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let userId = this.form.userId;
          let oldEmail = this.form.oldEmail;
          let newEmail = this.form.newEmail;
          let codeId = this.codeId;
          let code = this.form.code;
          //
          this.updateEmail({
            userId,
            oldEmail,
            newEmail,
            codeId,
            code,
          }).then(() => {
            if (this.status == 200) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.message,
              });
              this.onSubmit();
              this.dialogFormVisible = false;
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.message,
              });
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请先按规则填写好表单!",
          });
        }
      });
    },
    handleChangePassword(index, row) {
      this.form2.userId = row.user_id;
      this.form2.email = row.email;
      // console.log(index, row);
    },
    changePassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let userId = this.form2.userId;
          let newPassword = this.form2.newPassword;
          let checkNewPassword = this.form2.checkNewPassword;
          let codeId = this.codeId;
          let code = this.form2.code;
          let email = this.form2.email;
          //
          this.updatePassword({
            userId,
            newPassword,
            checkNewPassword,
            codeId,
            code,
            email,
          }).then(() => {
            if (this.status == 200) {
              this.$message({
                showClose: true,
                type: "success",
                message: this.message,
              });
              this.onSubmit();
              this.dialogFormVisible2 = false;
            } else {
              this.$message({
                showClose: true,
                type: "error",
                message: this.message,
              });
            }
          });
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请先按规则填写好表单!",
          });
        }
      });
    },
    cancel() {
      this.handleClose();
    },
    // getContent(content) {
    //   this.ruleForm.content = content;
    // },
    handleSizeChange(val) {
      this.formInline.pageSize = val;
      // console.log(`每页 ${val} 条`);
      this.onSubmit();
    },
    handleCurrentChange(val) {
      this.formInline.currentPage = val;
      // console.log(`当前页: ${val}`);
      this.onSubmit();
    },
    // handleClick(tab, event) {
    //   console.log(tab, event);
    // },
    getCode(email) {
      if (email) {
        this.getCodeId({ email }).then(() => {
          if (this.codeStatus == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.codeMessage,
            });
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.codeMessage,
            });
          }
        });
      } else {
        this.$message({
          showClose: true,
          type: "warning",
          message: "请先填写邮箱!",
        });
      }
    },
    // formatDate(timestamp) {
    //   const date = new Date(timestamp);
    //   const year = date.getFullYear();
    //   const month = String(date.getMonth() + 1).padStart(2, '0');
    //   const day = String(date.getDate()).padStart(2, '0');
    //   return `${year}-${month}-${day}`;
    // },
    // 查询账号列表
    onSubmit(nowPage) {
      let currentPage;
      if (nowPage) {
        this.currentPage = nowPage;
        currentPage = nowPage;
        this.formInline.currentPage = nowPage;
      }
      currentPage = this.formInline.currentPage;
      let enabledStatus = this.formInline.enabledStatus;
      let accountStatus = this.formInline.accountStatus;
      let pageSize = this.formInline.pageSize;
      let roleId = this.formInline.roleId;
      if (
        !enabledStatus &&
        enabledStatus !== 0 &&
        !accountStatus &&
        accountStatus !== 0 &&
        (roleId == null || roleId < 1 || roleId > 3)
      ) {
        // console.log("查询全部!", this.formInline);
        this.getAllUserinfoList({ currentPage, pageSize });
      } else if (
        !enabledStatus &&
        enabledStatus !== 0 &&
        (accountStatus || accountStatus === 0) &&
        (roleId == null || roleId < 1 || roleId > 3)
      ) {
        // console.log("账号状态查询!", this.formInline);
        this.getUserinfoListByAccountStatus({
          currentPage,
          pageSize,
          accountStatus,
        });
      } else if (
        (enabledStatus || enabledStatus === 0) &&
        !accountStatus &&
        accountStatus !== 0 &&
        (roleId == null || roleId < 1 || roleId > 3)
      ) {
        // console.log("禁用状态查询!", this.formInline);
        this.getUserinfoListByEnabledStatus({
          currentPage,
          pageSize,
          enabledStatus,
        });
      } else if (
        (enabledStatus || enabledStatus === 0) &&
        (accountStatus || accountStatus === 0) &&
        (roleId == null || roleId < 1 || roleId > 3)
      ) {
        // console.log("禁用状态 账号状态查询!", this.formInline);
        this.getUserinfoListByEnabledStatusAndAccountStatus({
          currentPage,
          pageSize,
          enabledStatus,
          accountStatus,
        });
      } else if (
        roleId <= 3 &&
        roleId >= 1 &&
        !enabledStatus &&
        enabledStatus !== 0 &&
        !accountStatus &&
        accountStatus !== 0
      ) {
        // 角色查询
        // console.log("角色查询!", this.formInline);
        this.getUserinfoListByRoleId({
          currentPage,
          pageSize,
          roleId,
        });
      } else if (
        (enabledStatus || enabledStatus === 0) &&
        !accountStatus &&
        accountStatus !== 0 &&
        roleId <= 3 &&
        roleId >= 1
      ) {
        // 角色 禁用 查询
        // console.log("角色 禁用状态 查询!", this.formInline);
        this.getUserinfoListByEnabledStatusAndRoleId({
          currentPage,
          pageSize,
          enabledStatus,
          roleId,
        });
      } else if (
        roleId <= 3 &&
        roleId >= 1 &&
        !enabledStatus &&
        enabledStatus !== 0 &&
        (accountStatus || accountStatus === 0)
      ) {
        // 角色 账号 查询
        // console.log("角色 账号状态 查询!", this.formInline);
        this.getUserinfoListByAccountStatusAndRoleId({
          currentPage,
          pageSize,
          accountStatus,
          roleId,
        });
      } else if (
        roleId <= 3 &&
        roleId >= 1 &&
        (enabledStatus || enabledStatus === 0) &&
        (accountStatus || accountStatus === 0)
      ) {
        // 角色 禁用 账号 查询
        // 角色 账号 查询
        // console.log("角色 禁用状态 账号状态 查询!", this.formInline);
        this.getUserinfoListByAll({
          currentPage,
          pageSize,
          enabledStatus,
          accountStatus,
          roleId,
        });
      }
      // 转换时间戳
      // this.userInfoList.user_create_time = this.formatDate(this.userInfoList.user_create_time)
      // this.tableData = this.userInfoList;
      // this.tableData = this.formattedUserInfoList;
      // console.log("formattedUserInfoList", this.formattedUserInfoList);
    },
    resetForm(formName) {
      this.formData = null;
      this.formData = new FormData();
      this.$refs[formName].resetFields();
    },
    handleEnable(index, row) {
      let enabledStatus = row.enabled_status == 1 ? 0 : 1;
      // console.log(enabledStatus);
      this.enableAccount({ enabledStatus, userId: row.user_id }).then(() => {
        if (this.status == 200) {
          this.$message({
            showClose: true,
            type: "success",
            message: this.message,
          });
          this.onSubmit();
        } else {
          this.$message({
            showClose: true,
            type: "error",
            message: this.message,
          });
        }
      });
    },
    // 删除
    // toggleSelection(rows) {
    //   console.log(rows);
    //   if (rows) {
    //     rows.forEach((row) => {
    //       this.$refs.multipleTable.toggleRowSelection(row);
    //     });
    //   } else {
    //     this.$refs.multipleTable.clearSelection();
    //   }
    // },
    handleSelectionChange(val) {
      this.multipleSelection = val;
      let ids = [];
      if (this.myUserInfo.user_id == 1) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (this.multipleSelection[i].role_name !== "超级管理员") {
            ids.push(this.multipleSelection[i].user_id);
          }
        }
      }
      if (this.myUserInfo.user_id != 1) {
        for (let i = 0; i < this.multipleSelection.length; i++) {
          if (
            this.multipleSelection[i].role_name !== "超级管理员" &&
            this.multipleSelection[i].role_name !== "管理员"
          ) {
            ids.push(this.multipleSelection[i].user_id);
          }
        }
      }
      this.userIds = ids;
      // console.log(this.userIds);
    },
    handleDelete(index, row) {
      let ids = [];
      ids.push(row.user_id);
      this.userIds = ids;
      if (this.userIds.length) {
        this.deleteAccount({ userIds: this.userIds }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleRecover(index, row) {
      let ids = [];
      ids.push(row.user_id);
      this.userIds = ids;
      if (this.userIds.length) {
        this.recoverAccount({ userIds: this.userIds }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleDeleteAll() {
      if (this.userIds.length) {
        this.deleteAccount({ userIds: this.userIds }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    handleRecoverAccountAll() {
      if (this.userIds.length) {
        this.recoverAccount({ userIds: this.userIds }).then(() => {
          if (this.status == 200) {
            this.$message({
              showClose: true,
              type: "success",
              message: this.message,
            });
            this.onSubmit();
          } else {
            this.$message({
              showClose: true,
              type: "error",
              message: this.message,
            });
          }
        });
      }
    },
    // 编辑
    handleEdit(index, row) {
      // this.ruleForm2.avatar = row.avatar;
      this.ruleForm2.username = row.user_name;
      this.ruleForm2.userId = row.user_id;
      this.ruleForm2.gender = row.gender;
      this.ruleForm2.avatar = row.avatar;
      this.avatarURL = row.avatar;
      // console.log(this.ruleForm2);
      // console.log(row);
      // this.content = row.content;
      this.drawer = true;
      if (this.file instanceof File) {
        this.imageUrl = URL.createObjectURL(this.file.raw);
      } else {
        this.imageUrl = row.image;
      }
    },
    // handleAvatarSuccess(res, file) {
    //   console.log(file);
    //   this.imageUrl = URL.createObjectURL(file.raw);
    // },
    handleClose(done) {
      this.$confirm("确认关闭？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          if (this.drawer) {
            this.resetForm("ruleForm");
            this.resetForm("ruleForm2");
            this.drawer = false;
          }
          if (this.dialogFormVisible) {
            this.resetForm("form");
            this.dialogFormVisible = false;
          }
          if (this.dialogFormVisible2) {
            this.resetForm("form2");
            this.dialogFormVisible2 = false;
          }
          done();
        })
        .catch(() => { });
    },
    confirm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.drawerTitle === "添加账号") {
            // console.log(this.ruleForm);
            // 调用添加账号接口方法
            this.addAccount({
              username: this.ruleForm.username,
              email: this.ruleForm.email,
              password: this.ruleForm.password,
              checkPassword: this.ruleForm.checkPassword,
              code: this.ruleForm.code,
              codeId: this.codeId,
            }).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
          if (this.drawerTitle === "编辑") {
            // console.log(this.ruleForm2);
            // 调用编辑接口方法
            this.formData.append("userId", this.ruleForm2.userId);
            this.formData.append("username", this.ruleForm2.username);
            this.formData.append("gender", this.ruleForm2.gender);
            // this.updateUserinfo({
            //   userId: this.ruleForm2.userId,
            //   username: this.ruleForm2.username,
            //   gender: this.ruleForm2.gender,
            //   avatar: this.ruleForm2.avatar,
            // })
            this.updateUserinfo(this.formData).then(() => {
              if (this.status == 200) {
                this.$message({
                  showClose: true,
                  type: "success",
                  message: this.message,
                });
                this.onSubmit();
                this.resetForm(formName);
                this.drawer = false;
              } else {
                this.$message({
                  showClose: true,
                  type: "error",
                  message: this.message,
                });
              }
            });
          }
        } else {
          this.$message({
            showClose: true,
            type: "warning",
            message: "请先填写好表单!",
          });
          return false;
        }
      });
    },

    // 裁剪头像相关函数
    editAvatar() {
      this.editAvatarDialog = true;
      this.option.img = this.avatarURL;
    },
    // 保存头像修改
    saveEditAvatar() {
      this.editAvatarDialog = false;
      this.finish("blob");
    },
    // 放大/缩小
    changeScale(num) {
      num = num || 1;
      this.$refs.cropper.changeScale(num);
    },
    // 左旋转
    rotateLeft() {
      this.$refs.cropper.rotateLeft();
    },
    // 右旋转
    rotateRight() {
      this.$refs.cropper.rotateRight();
    },
    // 保存上传图片
    finish(type) {
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          this.ruleForm2.avatar = data;
          if (data.type === "image/png") {
            this.filename = "avatar.png";
          }
          if (data.type === "image/jpeg") {
            this.filename = "avatar.jpeg";
          }
          if (data.type === "image/jpg") {
            this.filename = "avatar.jpeg";
          }
          if (data.type === "image/gif") {
            this.filename = "avatar.gif";
          }
          if (data.type === "image/webp") {
            this.filename = "avatar.webp";
          }
          if (data) {
            // console.log("添加了图片参数");
            this.formData.append(
              "avatar",
              this.ruleForm2.avatar,
              this.filename
            );
            this.avatarURL = window.URL.createObjectURL(this.ruleForm2.avatar);
          }
        });
      } else {
        // this.$refs.cropper.getCropData((data) => {
        //   return data;
        // });
      }
    },
    // 实时预览函数
    realTime(data) {
      this.previews = data;
    },
    // 下载图片
    down(type) {
      var aLink = document.createElement("a");
      aLink.download = "author-img";
      if (type === "blob") {
        this.$refs.cropper.getCropBlob((data) => {
          aLink.href = window.URL.createObjectURL(data);
          aLink.click();
        });
      } else {
        this.$refs.cropper.getCropData((data) => {
          aLink.href = data;
          aLink.click();
        });
      }
    },
    // 更换头像--上传本地图片
    uploadImg(file) {
      this.filename = file.name;
      var _this = this;
      var reader = new FileReader();
      reader.onload = (e) => {
        let data;
        if (typeof e.target.result === "object") {
          data = window.URL.createObjectURL(new Blob([e.target.result]));
        } else {
          data = e.target.result;
        }
        _this.option.img = data;
      };
      reader.readAsArrayBuffer(file.raw);
    },
  },
  mounted() {
    this.onSubmit();
    this.$nextTick(function () {
      this.height =
        window.innerHeight - this.$refs.multipleTable.$el.offsetTop - 220;
      // 监听窗口大小变化
      let self = this;
      window.onresize = function () {
        if (self.$refs.multipleTable) {
          self.height =
            window.innerHeight - self.$refs.multipleTable.$el.offsetTop - 220;
        }
      };
    });
  },
};
</script>

<style lang="less" scoped>
@color: #409eff;

.right {
  float: right;
}

.red {
  color: #f56c6c;
}

// 裁剪
.avatar_box {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.previewBox {
  text-align: center;
  margin-left: 60px;
}

.preview {
  // 裁剪预览图
  width: 500px;
  height: 400px;
  margin: 0px auto 20px auto;
  // border-radius: 50%;
  border: 1px solid #ccc;
  background-color: #ccc;
  overflow: hidden;
}

.cropper {
  // 裁剪盒子
  width: 500px;
  height: 400px;
}

.container {
  .el-tabs {
    /deep/ #tab-first {
      font-weight: 550;
      font-size: 1.25rem;
    }

    // /deep/ .el-tabs__item.is-active {
    //   color: @color;
    // }
    // /deep/ .el-tabs__active-bar {
    //   background: @color;
    //   height: 0.125rem;
    // }
    // /deep/ .el-tabs__nav-wrap::after {
    //   height: 0.125rem;
    // }
    .demo-form-inline {
      /deep/ .el-input__inner {
        width: 14.375rem;
      }

      /deep/ .el-form-item__label {
        padding: 0;
        font-size: 18px;
      }

      // .el-button {
      //   // background: @color;
      //   // font-size: 16px;
      //   // border: 1px solid @color;
      // }
      // .button_left {
      //   .button_reset {
      //     background: #fff;
      //     color: @color;
      //   }
      // }
      .button_right {
        float: right;

        .el-button {
          margin-left: 0.625rem;
        }

        // i {
        //   transform: translateY(2px);
        //   img {
        //     width: 16px;
        //   }
        // }
        // .el-button {
        //   border: 1px solid @color;
        // }
      }
    }

    /deep/ .el-table {
      font-size: 16px;

      .el-button {
        font-size: 16px;
        margin: 0 10px 0 0;
      }

      // .button_public {
      //   // display: none;
      // }
      .is_show {
        display: block;
      }

      .el-table__fixed-right::before,
      .el-table__fixed::before {
        height: 0;
      }

      &::before {
        display: none;
      }
    }

    /deep/ .el-dialog {
      .el-dialog__header {
        &:after {
          width: 95%;
          // height: calc(100% - 0.1rem);
          height: 1px;
          background-image: linear-gradient(to right,
              #ccc 0%,
              #ccc 50%,
              transparent 50%);
          background-size: 10px 1px;
          background-repeat: repeat-x;
          position: absolute;
          top: 3.5em;
          left: 1em;
          z-index: 1;
          content: "";
        }
      }

      .el-form-item__label {
        font-size: 18px;
      }
    }

    /deep/ .el-drawer__header {
      font-size: 20px;
      color: #333333;
      overflow: hidden;

      &:after {
        width: 93%;
        height: calc(100% - 0.1rem);
        background-image: linear-gradient(to right,
            #ccc 0%,
            #ccc 50%,
            transparent 50%);
        background-size: 10px 1px;
        background-repeat: repeat-x;
        position: absolute;
        top: 3em;
        content: "";
      }
    }

    /deep/ .el-drawer__body {
      padding: 10px 24px;
      margin-bottom: 20px;

      .el-form-item__label {
        font-size: 18px;
      }

      .zindex_max {
        z-index: 100000;
      }

      // .button_add {
      //   background: @color;
      //   border: @color;
      // }
      // .button_cancel {
      //   border: @color 1px solid;
      //   color: @color;
      // }
      .el-select {
        width: 100%;
      }
    }

    .avatar-uploader-icon {
      color: #8c939d;
      width: 104px;
      height: 104px;
      line-height: 104px;
      text-align: center;
      border: 1px dashed #ccc;
      border-radius: 5px;

      &:hover {
        border-color: @color;
      }
    }

    .avatar {
      width: 104px;
      height: 104px;
      display: block;
      border-radius: 5px;
    }
  }

  .el-pagination {
    float: right;
    position: relative;
    margin: 10px 0;
    padding: 0;

    /deep/ .el-pager .active {
      background-color: @color !important;
    }
  }
}
</style>
